import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './Footer';  // Footer dynamique

// Conteneur principal qui utilise Flexbox
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Hauteur minimale de 100% de la fenêtre */
`;

// Le contenu principal prend tout l'espace restant
const MainContent = styled.div`
  flex: 1;
  padding: 20px 40px; /* Réduction du padding pour corriger la position sur desktop */
  max-width: 600px; /* Limite la largeur du contenu sur desktop et tablette */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; /* Inclure le padding dans la largeur totale */

  @media (max-width: 768px) {
    padding: 20px 10px; /* Ajustez le padding sur les petits écrans */
    max-width: 100%; /* Assurez-vous que le contenu occupe tout l'espace sur mobile */
  }
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 20vw; /* Utilisez des unités relatives pour la fluidité */
  max-width: 150px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  width: 20vw; /* Unités relatives pour l'adaptation à l'écran */
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const Name = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  font-size: 24px; /* Taille adaptable */

  @media (max-width: 768px) {
    font-size: 20px; /* Ajuster la taille de la police pour les petits écrans */
  }
`;

const SubTitle = styled.h2`
  color: #00A2A2;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  margin-top: 0;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ContactInfo = styled.p`
  background-color: #00A2A2;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const AddressInfo = styled.p`
  background-color: #f2f6f6;
  color: #005357;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const MemberInfo = styled.p`
  background-color: #B6CC00;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Profile = () => {
  const { username } = useParams();
  const [member, setMember] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!username) {
      setError('Le nom d\'utilisateur est manquant');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`https://app.konex-business.fr/api/users?username=${username}`);
        if (!response.ok) {
          throw new Error('Erreur réseau lors de la récupération des données');
        }

        const data = await response.json();

        if (!data || !data.length) {
          throw new Error('Utilisateur non trouvé');
        }

        const user = data.find(user => user.username === username);
        if (!user) {
          throw new Error('Utilisateur non trouvé');
        }

        setMember(user);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
  }, [username]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!member) {
    return <div>Chargement...</div>;
  }

  // Définition de l'image de profil par défaut
  const profileImageUrl = (member.profile_picture && !member.profile_picture.includes('via.placeholder.com'))
    ? (member.profile_picture.startsWith('http')
      ? member.profile_picture
      : `https://konex-business.fr/${member.profile_picture}`)
    : 'https://app.konex-business.fr/favicon2.png';  // Image par défaut si aucune photo n'est définie

  // Définition du logo par défaut
  const logoUrl = (member.logo && !member.logo.includes('via.placeholder.com'))
    ? (member.logo.startsWith('http')
      ? member.logo
      : `https://konex-business.fr/${member.logo}`)
    : 'https://app.konex-business.fr/favicon2.png';  // Image par défaut si aucun logo n'est défini

  return (
    <PageContainer>
      <MainContent>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ProfileImage
            src={profileImageUrl}
            alt={`Photo de profil de ${member.prenom} ${member.nom}`}
          />
          <LogoImage
            src={logoUrl}
            alt={`Logo de ${member.societe || 'Logo par défaut'}`}
          />
        </div>
        <Name>{`${member.prenom} ${member.nom}`}</Name> {/* Le nom est affiché ici */}
        {member.societe && <SubTitle>{member.societe}</SubTitle>}
        {member.activite && <SubTitle>{member.activite}</SubTitle>}
        {member.telephone && <ContactInfo>{member.telephone}</ContactInfo>}
        {member.email && <ContactInfo>{member.email}</ContactInfo>}
        {member.website_url && <AddressInfo>{member.website_url}</AddressInfo>}
        {member.adresse && <AddressInfo>{`Adresse: ${member.adresse}`}</AddressInfo>}
        {member.cp && member.ville && (
          <AddressInfo>{`CP: ${member.cp}, Ville: ${member.ville}`}</AddressInfo>
        )}
        {member.created_at && (
          <MemberInfo>{`Membre KONEX depuis : ${new Date(member.created_at).toLocaleDateString()}`}</MemberInfo>
        )}
      </MainContent>

      {/* Footer dynamique */}
      <Footer />
    </PageContainer>
  );
};

export default Profile;

