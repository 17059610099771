import React, { useEffect, useState } from 'react';

// Fonction pour envoyer des notifications à tous les utilisateurs
export const sendNotificationToUsers = async (message) => {
  try {
    const timestamp = new Date().toISOString().slice(0, 19).replace('T', ' ');

    const response = await fetch('https://app.konex-business.fr/api/notifications', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
        timestamp: timestamp,
      }),
    });

    const data = await response.json();
    if (!data.success) {
      throw new Error('Erreur lors de l\'envoi de la notification');
    }
  } catch (error) {
    console.error('Erreur lors de l\'envoi de la notification:', error);
  }
};

// Fonction pour récupérer les notifications
export const fetchNotifications = async () => {
  try {
    const response = await fetch('https://app.konex-business.fr/api/notifications', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des notifications');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erreur lors de la récupération des notifications:', error);
    return [];
  }
};

// Fonction pour marquer toutes les notifications comme lues
export const markAllNotificationsAsRead = async () => {
  try {
    const response = await fetch('https://app.konex-business.fr/api/notifications/mark-as-read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Erreur lors de la mise à jour des notifications');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour des notifications:', error);
  }
};

// Fonction pour marquer une notification spécifique comme lue
export const markNotificationAsRead = async (id) => {
  try {
    const response = await fetch(`https://app.konex-business.fr/api/notifications/${id}/mark-as-read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Erreur lors de la mise à jour de la notification');
    }
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la notification:', error);
  }
};

// Composant pour gérer et afficher les notifications en temps réel avec Server-Sent Events (SSE)
const NotificationsComponent = () => {
  const [notifications, setNotifications] = useState([]);

  // Utilisation d'un effet pour connecter SSE
  useEffect(() => {
    // Connexion au serveur avec SSE
    const eventSource = new EventSource('https://app.konex-business.fr/api/notifications/stream');

    // Écouter les notifications en temps réel
    eventSource.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      setNotifications((prevNotifications) => [notification, ...prevNotifications]);
    };

    // Nettoyage de la connexion SSE lors du démontage du composant
    return () => {
      eventSource.close();
    };
  }, []);

  // Fonction pour charger les notifications existantes au chargement de la page
  useEffect(() => {
    const loadNotifications = async () => {
      const data = await fetchNotifications();
      setNotifications(data);
    };
    loadNotifications();
  }, []);

  return (
    <div>
      <h3>Notifications en temps réel</h3>
      <ul>
        {notifications.map((notif, index) => (
          <li key={index}>
            {notif.message} - {notif.timestamp}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationsComponent;

