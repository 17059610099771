import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  text-align: center;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 80%;
  margin: 0 auto 40px auto;
`;

const Input = styled.input`
  padding: 15px 20px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  width: 100%;
  background-color: #f2f6f6;
  box-sizing: border-box;
`;

const SearchButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #005357;
`;

const MemberList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MemberCard = styled.div`
  display: flex;
  align-items: center;
  background-color: #f2f6f6;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%; /* même largeur que la barre de recherche */
  box-sizing: border-box;
  cursor: pointer; /* Ajout du curseur de pointeur */
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 80px; /* Taille augmentée */
  height: 80px; /* Taille augmentée */
  object-fit: cover;
  margin-right: 20px;
`;

const MemberDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.h2`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  margin: 0;
`;

const MemberInfo = styled.p`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  margin: 0;
`;

function Membres() {
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Utiliser useNavigate pour la navigation

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/users')
      .then(response => response.json())
      .then(data => {
        setMembers(data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des membres:', error);
      });
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCardClick = (username) => {
    console.log(`Navigating to profile: ${username}`); // Log username
    navigate(`/profile/${username}`); // Naviguer vers le profil de l'utilisateur
  };

  const filteredMembers = members.filter(member =>
    member.nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.prenom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container>
      <Title>Membres</Title>
      <SearchBarContainer>
        <Input
          type="text"
          placeholder="Rechercher un membre..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <SearchButton>
          <FaSearch size={20} />
        </SearchButton>
      </SearchBarContainer>
      <MemberList>
        {filteredMembers.map(member => {
          const profileImageUrl = `https://konex-business.fr/${member.profile_picture}`;
          console.log(`Profile image URL for ${member.nom} ${member.prenom}: ${profileImageUrl}`);
          
          return (
            <MemberCard key={member.id} onClick={() => handleCardClick(member.username)}> {/* Naviguer sur le clic */}
              <ProfileImage src={profileImageUrl} alt={`${member.nom} ${member.prenom}`} />
              <MemberDetails>
                <MemberName>{member.prenom} {member.nom}</MemberName>
                <MemberInfo>Société: {member.societe || 'N/A'}</MemberInfo>
                <MemberInfo>Activité: {member.activite || 'N/A'}</MemberInfo>
              </MemberDetails>
            </MemberCard>
          );
        })}
      </MemberList>
    </Container>
  );
}

export default Membres;

