import React, { useState } from 'react';
import styled from 'styled-components';

// Importer la fonction pour envoyer des notifications
import { sendNotificationToUsers } from './notificationService'; // Assurez-vous que ce service est bien implémenté

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

const Label = styled.label`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  margin-right: 20px;

  & > input {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 10px;
  box-sizing: border-box;

  &:nth-child(2) {
    margin-bottom: 30px;
  }

  &:nth-child(3) {
    margin-bottom: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  & > div {
    flex: 1;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const Input = styled.input`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%;
  }

  &[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    padding: 15px;
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  height: 120px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 150px;
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 18px;
    font-size: 18px;
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Message = styled.p`
  margin-top: 20px;
  color: ${(props) => (props.error ? 'red' : 'green')};
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
`;

function EntrerUnInvite() {
  const [typeVisite, setTypeVisite] = useState('Visiteur');
  const [dateVisite, setDateVisite] = useState('');
  const [activite, setActivite] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [societe, setSociete] = useState('');
  const [telephone, setTelephone] = useState('');
  const [mail, setMail] = useState('');
  const [adresse, setAdresse] = useState('');
  const [cp, setCp] = useState('');
  const [ville, setVille] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    const inviteData = {
      type_visite: typeVisite,
      date_visite: dateVisite,
      activite: activite,
      nom: nom,
      prenom: prenom,
      societe: societe,
      telephone: telephone,
      mail: mail,
      adresse: adresse,
      cp: cp,
      ville: ville,
      description: description,
    };

    try {
      const response = await fetch('https://app.konex-business.fr/api/invites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inviteData),
      });

      const data = await response.json();
      if (data.success) {
        setMessage('Invité enregistré avec succès');

        // Envoyer la notification après l'ajout de l'invité
        const notificationMessage = `Nouvel invité ajouté par ${prenom} ${nom} de ${societe}.`;
        await sendNotificationToUsers(notificationMessage);
      } else {
        setError("Erreur lors de l'enregistrement de l'invité");
      }
    } catch (error) {
      setError("Erreur lors de l'enregistrement de l'invité");
    }
  };

  return (
    <Container>
      <Title>Entrer un invité</Title>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Label htmlFor="typeVisite">Type de visite :</Label>
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
            <RadioLabel>
              <input
                type="radio"
                value="Visiteur"
                checked={typeVisite === 'Visiteur'}
                onChange={(e) => setTypeVisite(e.target.value)}
              />
              Visiteur
            </RadioLabel>
            <RadioLabel>
              <input
                type="radio"
                value="Klone"
                checked={typeVisite === 'Klone'}
                onChange={(e) => setTypeVisite(e.target.value)}
              />
              Klone
            </RadioLabel>
          </div>
        </FormControl>
        <FormControl>
          <Label htmlFor="dateVisite">Date de visite :</Label>
          <Input
            id="dateVisite"
            type="date"
            value={dateVisite}
            onChange={(e) => setDateVisite(e.target.value)}
            placeholder="jj/mm/aaaa"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="activite">Activité :</Label>
          <Input
            id="activite"
            type="text"
            value={activite}
            onChange={(e) => setActivite(e.target.value)}
            placeholder="Activité"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="nom">Nom*</Label>
          <Input
            id="nom"
            type="text"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            placeholder="Nom"
            required
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="prenom">Prénom*</Label>
          <Input
            id="prenom"
            type="text"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            placeholder="Prénom"
            required
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="societe">Société*</Label>
          <Input
            id="societe"
            type="text"
            value={societe}
            onChange={(e) => setSociete(e.target.value)}
            placeholder="Société"
            required
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="telephone">Téléphone*</Label>
          <Input
            id="telephone"
            type="text"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            placeholder="Téléphone"
            required
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="mail">Mail</Label>
          <Input
            id="mail"
            type="email"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Mail"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="adresse">Adresse</Label>
          <Input
            id="adresse"
            type="text"
            value={adresse}
            onChange={(e) => setAdresse(e.target.value)}
            placeholder="Adresse"
          />
        </FormControl>
        <Row>
          <FormControl>
            <Label htmlFor="cp">CP</Label>
            <Input
              id="cp"
              type="text"
              value={cp}
              onChange={(e) => setCp(e.target.value)}
              placeholder="CP"
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="ville">Ville</Label>
            <Input
              id="ville"
              type="text"
              value={ville}
              onChange={(e) => setVille(e.target.value)}
              placeholder="Ville"
            />
          </FormControl>
        </Row>
        <FormControl>
          <Label htmlFor="description">Description</Label>
          <TextArea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
        </FormControl>
        <FormControl>
          <Button type="submit">Entrer</Button>
        </FormControl>
        {message && <Message>{message}</Message>}
        {error && <Message error>{error}</Message>}
      </Form>
    </Container>
  );
}

export default EntrerUnInvite;

