import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import UserContext from './UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
    box-shadow: none;
  }
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Select = styled.select`
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
  }
`;

const Input = styled.input`
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
  }
`;

const TextArea = styled.textarea`
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  height: 150px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px;
    height: 120px;
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Message = styled.p`
  color: ${(props) => (props.success ? '#00A2A2' : 'red')};
  margin-top: 20px;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
`;

function EntrerUnMerci() {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [merci, setMerci] = useState('');
  const [connexions, setConnexions] = useState([]);
  const [filteredConnexions, setFilteredConnexions] = useState([]);
  const [selectedConnexion, setSelectedConnexion] = useState('');
  const [montant, setMontant] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false); // Gestion du succès ou de l'erreur

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/users')
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error('Erreur lors de la récupération des utilisateurs:', error));

    if (user) {
      fetch(`https://app.konex-business.fr/api/connections_received?user_id=${user.id}`)
        .then((response) => response.json())
        .then((data) => setConnexions(data))
        .catch((error) => console.error('Erreur lors de la récupération des connexions:', error));
    }
  }, [user]);

  useEffect(() => {
    if (merci) {
      const userConnexions = connexions.filter(connexion => connexion.user_id === parseInt(merci));
      setFilteredConnexions(userConnexions);
    } else {
      setFilteredConnexions([]);
    }
  }, [merci, connexions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    if (!merci || !selectedConnexion || !montant || !description) {
      setMessage('Veuillez remplir tous les champs obligatoires');
      setIsSuccess(false);
      return;
    }

    const merciData = {
      userId: user.id,
      merciId: merci,
      connexionId: selectedConnexion,
      montant,
      description,
    };

    try {
      const response = await fetch('https://app.konex-business.fr/api/mercis', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(merciData)
      });

      const data = await response.json();
      if (data.success) {
        setMessage('Merci enregistré avec succès');
        setIsSuccess(true); // Affiche le message de succès en vert
      } else {
        setMessage('Erreur lors de l\'enregistrement du merci');
        setIsSuccess(false); // Affiche le message d'erreur en rouge
      }
    } catch (error) {
      setMessage('Erreur lors de l\'enregistrement du merci');
      setIsSuccess(false); // Affiche le message d'erreur en rouge
    }
  };

  return (
    <Container>
      <Title>Entrer un merci</Title>
      <Form onSubmit={handleSubmit}>
        <Label htmlFor="merci">Merci à</Label>
        <Select id="merci" value={merci} onChange={(e) => setMerci(e.target.value)}>
          <option value="">Choisir un utilisateur</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.nom} {user.prenom}
            </option>
          ))}
        </Select>
        <Label htmlFor="connexion">Choix de la connexion</Label>
        <Select id="connexion" value={selectedConnexion} onChange={(e) => setSelectedConnexion(e.target.value)}>
          <option value="">Choisir une connexion</option>
          {filteredConnexions.map((connexion) => (
            <option key={connexion.id} value={connexion.id}>
              {connexion.nom} {connexion.prenom}
            </option>
          ))}
        </Select>
        <Label htmlFor="montant">Montant*</Label>
        <Input
          id="montant"
          type="number"
          value={montant}
          onChange={(e) => setMontant(e.target.value)}
          placeholder="Montant"
        />
        <Label htmlFor="description">Description*</Label>
        <TextArea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <Button type="submit">Entrer</Button>
        {message && <Message success={isSuccess}>{message}</Message>}
      </Form>
    </Container>
  );
}

export default EntrerUnMerci;

