import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Hauteur par défaut du footer */
    background: var(--vert-clair-konex, #B6CC00);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-family: 'Avenir Next', sans-serif;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 0 20px;

    /* Media query pour les petits appareils (smartphones) */
    @media (max-width: 768px) {
        height: 45px; /* Réduction de la hauteur du footer sur petits écrans */
        font-size: 14px;
    }
`;

const ContentContainer = styled.div`
    padding-bottom: 30px; /* Espace par défaut entre le contenu et le footer */

    /* Media query pour les petits écrans */
    @media (max-width: 768px) {
        padding-bottom: 15px; /* Réduction de l'espace pour les petits écrans */
    }
`;

const Footer = () => {
    return (
        <FooterContainer>
            &copy; {new Date().getFullYear()} Konex-Business. Tous droits réservés.
        </FooterContainer>
    );
};

const PageContent = () => {
    return (
        <ContentContainer>
            {/* Contenu principal ici */}
        </ContentContainer>
    );
};

export default Footer;

