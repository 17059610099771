import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import UserContext from './UserContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const InfoMessage = styled.p`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px;
    box-shadow: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  & > div {
    flex: 1;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const Label = styled.label`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #d9e1e8;
    padding: 10px;
    text-align: center;
    font-family: 'Avenir Next', sans-serif;
    color: #005357;
    background-color: #f2f6f6;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 8px;
    }
  }

  th {
    font-weight: 700;
    color: #ffffff;
  }

  th:first-child {
    background-color: #B6CC00; /* Connexions Données */
  }

  th:nth-child(2) {
    background-color: #00A2A2; /* Connexions Reçues */
  }

  th:nth-child(3) {
    background-color: #005357; /* Entretiens */
  }

  th:nth-child(4) {
    background-color: #005357; /* Invités */
  }

  th:nth-child(5) {
    background-color: #B6CC00; /* Merci Donnés */
  }

  th:nth-child(6) {
    background-color: #00A2A2; /* Merci Reçus */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 15px;
  background-color: #00A2A2;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 200px; /* Adjust the width as needed */

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px;
  }
`;

const FormControl = styled.div`
  margin-bottom: 20px;
  flex: 1;
`;

function StatistiquesMembre() {
  const { user } = useContext(UserContext);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);

    setDateDebut(lastMonth.toISOString().substr(0, 10));
    setDateFin(today.toISOString().substr(0, 10));

    fetchStats(user.id, lastMonth, today);
  }, [user]);

  const fetchStats = async (userId, startDate, endDate) => {
    try {
      const response = await fetch(`https://app.konex-business.fr/api/stats?user_id=${userId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`);
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchStats(user.id, new Date(dateDebut), new Date(dateFin));
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Connexions Données', 'Connexions Reçues', 'Entretiens', 'Invités', 'Merci Donnés', 'Merci Reçus']],
      body: [[
        stats.connexions_donnees,
        stats.connexions_recues,
        stats.entretiens_donnees,
        stats.invites,
        stats.mercis_donnees,
        stats.mercis_recues
      ]]
    });
    doc.save('rapport_statistiques.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet([{
      'Connexions Données': stats.connexions_donnees,
      'Connexions Reçues': stats.connexions_recues,
      Entretiens: stats.entretiens_donnees,
      Invités: stats.invites,
      'Merci Donnés': stats.mercis_donnees,
      'Merci Reçus': stats.mercis_recues
    }]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Statistiques');
    XLSX.writeFile(workbook, 'rapport_statistiques.xlsx');
  };

  return (
    <Container>
      <Title>Rapport statistiques Membre</Title>
      <InfoMessage>Affichage des statistiques mensuelles par défaut. Utilisez les sélecteurs de date pour modifier la période.</InfoMessage>
      <Form onSubmit={handleSubmit}>
        <Row>
          <FormControl>
            <Label htmlFor="dateDebut">Du :</Label>
            <Input
              id="dateDebut"
              type="date"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="dateFin">Au :</Label>
            <Input
              id="dateFin"
              type="date"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
            />
          </FormControl>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Connexions Données</th>
              <th>Connexions Reçues</th>
              <th>Entretiens</th>
              <th>Invités</th>
              <th>Merci Donnés</th>
              <th>Merci Reçus</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{stats.connexions_donnees}</td>
              <td>{stats.connexions_recues}</td>
              <td>{stats.entretiens_donnees}</td>
              <td>{stats.invites}</td>
              <td>{stats.mercis_donnees}</td>
              <td>{stats.mercis_recues}</td>
            </tr>
          </tbody>
        </Table>
        <ButtonContainer>
          <Button type="button" onClick={downloadPDF}>Télécharger Rapport PDF</Button>
          <Button type="button" onClick={downloadExcel}>Télécharger Rapport Excel</Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
}

export default StatistiquesMembre;

