import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserContext from './UserContext';
import { FaHandshake, FaUsers, FaEuroSign, FaUser } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
`;

const Banner = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #005357;
  color: #ffffff;
  padding: 10px 20px;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  font-size: 24px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const RecapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const RecapItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #00A2A2;
  width: 22%;
  background-color: #f2f6f6;
  height: 150px;
  border-radius: 0;
  justify-content: center;
`;

const RecapIcon = styled.div`
  font-size: 36px;
  color: #005357;
  margin-bottom: 10px;
`;

const RecapValue = styled.div`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const RecapLabel = styled.div`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
`;

const RecapButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
`;

const RecapButton = styled.button`
  background-color: ${props => props.active ? '#b1d334' : '#00A2A2'};
  color: ${props => props.active ? '#005357' : '#ffffff'};
  padding: 20px 60px;
  text-decoration: none;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  border-radius: 0;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.active ? '#9baf2e' : '#007c7c'};
  }
  text-align: center;
`;

const SubSectionTitle = styled.h3`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
`;

const RecapSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const RecapSubItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #00A2A2;
  width: 22%;
  background-color: #f2f6f6;
  height: 150px;
  border-radius: 0;
  justify-content: center;
`;

const FilterBanner = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #00A2A2;
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const FilterButton = styled.button`
  background-color: #00A2A2;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  border-radius: 0;
  margin: 0 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #005357;
  }
`;

const FilterDropdown = styled.div`
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #00A2A2;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const Accueil = () => {
  const { user } = useContext(UserContext);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('6mois');
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 6)));
  const [endDate, setEndDate] = useState(new Date());
  const [groupStats, setGroupStats] = useState({
    connexions: 0,
    entrevues: 0,
    invites: 0,
    mercis: 0
  });
  const [personalStats, setPersonalStats] = useState({
    connexions_donnees: 0,
    mercis_donnees: 0,
    invites: 0,
    connexions_recues: 0,
    mercis_recues: 0,
    entretiens_donnees: 0,
    entretiens_recues: 0
  });
  const datePickerRef = useRef(null);

  const apiUrl = process.env.NODE_ENV === 'production' ? 'https://app.konex-business.fr' : 'http://localhost:5000';

  const fetchGroupStats = useCallback(async () => {
    try {
      const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
      const endDate = new Date().toISOString();
      console.log(`Fetching group stats from ${startDate} to ${endDate}`); // Log pour le debugging
      const response = await fetch(`${apiUrl}/api/group-stats?startDate=${startDate}&endDate=${endDate}`);
      const data = await response.json();
      console.log('Group stats data:', data); // Log des données pour le diagnostic
      setGroupStats(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques de groupe:', error);
    }
  }, [apiUrl]);

  const fetchPersonalStats = useCallback(async () => {
    if (!startDate || !endDate) return; // Assurez-vous que les dates ne sont pas nulles
    try {
      const startDateStr = startDate.toISOString();
      const endDateStr = endDate.toISOString();
      console.log(`Fetching personal stats from ${startDateStr} to ${endDateStr} for user ${user.id}`); // Log pour le debugging
      const response = await fetch(`${apiUrl}/api/personal-stats?user_id=${user.id}&startDate=${startDateStr}&endDate=${endDateStr}`);
      const data = await response.json();
      console.log('Personal stats data:', data); // Log des données pour le diagnostic
      setPersonalStats(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des statistiques personnelles:', error);
    }
  }, [apiUrl, user.id, startDate, endDate]);

  useEffect(() => {
    fetchGroupStats();
    fetchPersonalStats();
  }, [fetchGroupStats, fetchPersonalStats]);

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    let newStartDate = new Date();
    let newEndDate = new Date();
    if (period === '6mois') {
      newStartDate.setMonth(newStartDate.getMonth() - 6);
      setDropdownVisible(false);
    } else if (period === '12mois') {
      newStartDate.setFullYear(newStartDate.getFullYear() - 1);
      setDropdownVisible(false);
    } else if (period === 'historique') {
      setDropdownVisible(true);
    }
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]);

  return (
    <Container>
      <Title>Bienvenue chez KONEX !</Title>
      
      <Banner>Récapitulatif Mensuel du Groupe</Banner>
      <Section>
        <RecapContainer>
          <RecapItem>
            <RecapIcon><FaHandshake /></RecapIcon>
            <RecapValue>{groupStats.connexions}</RecapValue>
            <RecapLabel>Connexion</RecapLabel>
          </RecapItem>
          <RecapItem>
            <RecapIcon><FaUsers /></RecapIcon>
            <RecapValue>{groupStats.entrevues}</RecapValue>
            <RecapLabel>Entrevue</RecapLabel>
          </RecapItem>
          <RecapItem>
            <RecapIcon><FaUser /></RecapIcon>
            <RecapValue>{groupStats.invites}</RecapValue>
            <RecapLabel>Invité</RecapLabel>
          </RecapItem>
          <RecapItem>
            <RecapIcon><FaEuroSign /></RecapIcon>
            <RecapValue>{groupStats.mercis}€</RecapValue>
            <RecapLabel>Merci</RecapLabel>
          </RecapItem>
        </RecapContainer>
      </Section>

      <Banner>Récapitulatif Personnel</Banner>
      <Section>
        <RecapButtonContainer>
          <RecapButton as={Link} to="/bonsdonnes" active>« BONS » DONNÉS</RecapButton>
          <RecapButton as={Link} to="/bonsrecus">« BONS » REÇUS</RecapButton>
        </RecapButtonContainer>
        <FilterBanner>
          <FilterButton onClick={() => handlePeriodChange('6mois')} active={selectedPeriod === '6mois'}>6 MOIS</FilterButton>
          <FilterButton onClick={() => handlePeriodChange('12mois')} active={selectedPeriod === '12mois'}>12 MOIS</FilterButton>
          <FilterButton onClick={() => handlePeriodChange('historique')} active={selectedPeriod === 'historique'}>HISTORIQUE</FilterButton>
        </FilterBanner>
        {selectedPeriod === 'historique' && (
          <FilterDropdown ref={datePickerRef} visible={isDropdownVisible}>
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </FilterDropdown>
        )}
        <RecapSubContainer>
          <RecapSubItem>
            <SubSectionTitle>Connexion Données</SubSectionTitle>
            <RecapIcon><FaHandshake /></RecapIcon>
            <RecapValue>{personalStats.connexions_donnees}</RecapValue>
          </RecapSubItem>
          <RecapSubItem>
            <SubSectionTitle>Merci Donnés</SubSectionTitle>
            <RecapIcon><FaEuroSign /></RecapIcon>
            <RecapValue>{personalStats.mercis_donnees}€</RecapValue>
          </RecapSubItem>
          <RecapSubItem>
            <SubSectionTitle>Invité</SubSectionTitle>
            <RecapIcon><FaUser /></RecapIcon>
            <RecapValue>{personalStats.invites}</RecapValue>
          </RecapSubItem>
        </RecapSubContainer>
        <RecapSubContainer>
          <RecapSubItem>
            <SubSectionTitle>Connexion Reçues</SubSectionTitle>
            <RecapIcon><FaHandshake /></RecapIcon>
            <RecapValue>{personalStats.connexions_recues}</RecapValue>
          </RecapSubItem>
          <RecapSubItem>
            <SubSectionTitle>Merci Reçus</SubSectionTitle>
            <RecapIcon><FaEuroSign /></RecapIcon>
            <RecapValue>{personalStats.mercis_recues}€</RecapValue>
          </RecapSubItem>
          <RecapSubItem>
            <SubSectionTitle>Entrevue</SubSectionTitle>
            <RecapIcon><FaUsers /></RecapIcon>
            <RecapValue>{personalStats.entretiens_donnees}</RecapValue>
          </RecapSubItem>
        </RecapSubContainer>
      </Section>
    </Container>
  );
};

export default Accueil;

