import React, { useReducer, useContext, useMemo, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import {
  FaHome, FaUsers, FaUser, FaEuroSign, FaChartBar, FaBell, FaTh, FaHandshake, FaSignInAlt, FaFileInvoice, FaArrowLeft, FaSignOutAlt, FaUserTie, FaMoneyBillWave, FaHandsHelping
} from 'react-icons/fa';
import UserContext from './UserContext';
import { fetchNotifications, markAllNotificationsAsRead } from './notificationService';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    padding-top: 60px; /* Compensation pour le header */
  }

  a {
    color: white;
    text-decoration: none;
  }

  button {
    background: none;
    border: none;
    color: #00A2A2;
    cursor: pointer;
  }

  button:focus {
    outline: 2px solid #00A2A2;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 60px;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

const NavIcons = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;
  color: #00A2A2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const IconLink = styled(NavLink)`
  color: ${({ active }) => (active ? '#005f5f' : 'inherit')};
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ProfileImage = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  transition: filter 0.3s ease;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
  }
`;

const ProfileMenu = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1001;
  display: ${({ open }) => (open ? 'block' : 'none')};

  a, button {
    display: block;
    padding: 10px 20px;
    text-align: left;
    width: 100%;
    color: #00A2A2;
    transition: background-color 0.3s ease;
  }

  a:hover, button:hover {
    background-color: #f3fbff;
  }
`;

const MenuButton = styled.button`
  color: #00A2A2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const NotificationButton = styled.button`
  color: #00A2A2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.1s ease;
  position: relative;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    width: 45px;
    height: 45px;
  }
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const StyledFaTh = styled(FaTh)`
  font-size: 24px;
  color: #00A2A2;
`;

const ContentContainer = styled.main`
  padding: 20px;
`;

const MenuContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00A2A2;
  color: white;
  padding: 80px 5% 20px 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 999;
  border-radius: 9px;
  overflow-y: auto;
  max-height: 100vh;
  animation: ${({ open }) => (open ? slideIn : slideOut)} 0.3s ease;

  @media (min-width: 769px) {
    width: 300px;
    left: 0;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SectionTitle = styled.h3`
  color: white;
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  text-align: left;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 15px;
  border-radius: 9px;
  text-decoration: none;
  color: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

const MenuIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

const MenuText = styled.span`
  font-size: 1.2rem;
  color: inherit;
`;

const SubMenu = styled.div`
  margin-top: 30px;
`;

const BackLink = styled.button`
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  background: none;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const initialState = {
  menuOpen: false,
  inActivitySubMenu: false,
  profileMenuOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state, menuOpen: !state.menuOpen };
    case 'OPEN_ACTIVITY_SUBMENU':
      return { ...state, inActivitySubMenu: true };
    case 'CLOSE_ACTIVITY_SUBMENU':
      return { ...state, inActivitySubMenu: false };
    case 'CLOSE_MENU':
      return { ...state, menuOpen: false, inActivitySubMenu: false };
    case 'TOGGLE_PROFILE_MENU':
      return { ...state, profileMenuOpen: !state.profileMenuOpen };
    case 'CLOSE_PROFILE_MENU':
      return { ...state, profileMenuOpen: false };
    default:
      return state;
  }
};

const MenuComponent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(UserContext);

  // Utilisation de SSE pour recevoir les notifications
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const eventSource = new EventSource('https://app.konex-business.fr/api/notifications/stream');

    eventSource.onmessage = function(event) {
      try {
        const data = JSON.parse(event.data);
        setNotifications(prev => [...prev, data]);
        setUnreadCount(prevCount => prevCount + 1); // Incrémenter les notifications non lues
      } catch (error) {
        console.error("Erreur lors du parsing JSON : ", error);
      }
    };

    eventSource.onerror = function(error) {
      console.error("Erreur de connexion SSE : ", error);
      console.log("Détails de l'erreur : ", error); // Loguer plus de détails
      eventSource.close(); // Fermer la connexion
    };


    return () => {
      eventSource.close(); // Fermer la connexion SSE lorsque le composant est démonté
    };
  }, []);

  const handleProfileClick = () => {
    if (user?.username) {
      navigate(`/profile/${user.username}`);
    } else {
      console.error("Identifiant utilisateur introuvable.");
    }
  };

  const profileImageUrl = useMemo(() => {
    return user?.profile_picture
      ? `https://konex-business.fr/${user.profile_picture}`
      : "https://via.placeholder.com/45"; // Placeholder si pas de photo
  }, [user]);

  const toggleMenu = () => dispatch({ type: 'TOGGLE_MENU' });
  const openActivitySubMenu = () => dispatch({ type: 'OPEN_ACTIVITY_SUBMENU' });
  const closeActivitySubMenu = () => dispatch({ type: 'CLOSE_ACTIVITY_SUBMENU' });
  const toggleProfileMenu = () => dispatch({ type: 'TOGGLE_PROFILE_MENU' });
  const closeProfileMenu = () => dispatch({ type: 'CLOSE_PROFILE_MENU' });

  const handleLogout = () => {
    closeProfileMenu();
    logout();
    navigate('/seconnecter');
  };

  const handleNotificationsClick = async () => {
    fetch('/api/notifications/mark-as-read', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de la mise à jour des notifications');
        }
        return response.json();
      })
      .then(data => {
        console.log('Notifications mises à jour avec succès:', data);
        setUnreadCount(0); // Réinitialiser le compteur de notifications non lues
      })
      .catch(error => {
        console.error('Erreur dans le traitement de la réponse:', error);
      });
  
    dispatch({ type: 'CLOSE_MENU' });
  };
  

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [location.pathname]);

  return (
    <>
      <GlobalStyle />
      <Header>
        <Logo
          src="https://app.konex-business.fr/favicon2.png"
          alt="Logo Konex Business"
          onClick={() => {
            navigate('/');
            dispatch({ type: 'CLOSE_MENU' });
          }}
        />
        <NavIcons aria-label="Navigation principale">
          <IconLink to="/" exact activeClassName="active" aria-label="Accueil">
            <FaHome />
          </IconLink>
          <IconLink to="/membres" activeClassName="active" aria-label="Liste des Membres">
            <FaUsers />
          </IconLink>
          <IconLink
            to={`/profile/${user?.username}`}
            activeClassName="active"
            aria-label="Profil"
          >
            <FaUser />
          </IconLink>
          <IconLink to="/recapmercirecu" activeClassName="active" aria-label="Recap Merci Reçu">
            <FaEuroSign /> {/* Mise à jour avec l'icône d'Euro */}
          </IconLink>
          <IconLink to="/statistiquesmembre" activeClassName="active" aria-label="Statistiques">
            <FaChartBar />
          </IconLink>
        </NavIcons>
        <ProfileSection>
          <NotificationButton aria-label="Notifications" onClick={handleNotificationsClick}>
            <FaBell />
            <NotificationBadge show={unreadCount > 0} />
          </NotificationButton>
          <MenuButton onClick={toggleMenu} aria-label="Menu">
            <StyledFaTh />
          </MenuButton>
          <ProfileImage
            src={profileImageUrl}
            alt={`Photo de profil de ${user?.prenom} ${user?.nom}`}
            onClick={toggleProfileMenu}
          />
          {state.profileMenuOpen && (
            <ProfileMenu open={state.profileMenuOpen}>
              <a href="https://konex-business.fr">Aller vers le site</a>
              <button onClick={handleLogout}>Déconnexion</button>
            </ProfileMenu>
          )}
        </ProfileSection>
      </Header>
      <ContentContainer>
        {/* Contenu principal de la page */}
      </ContentContainer>
      {state.menuOpen && (
        <MenuContainer open={state.menuOpen}>
          {!state.inActivitySubMenu ? (
            <>
              <SectionTitle>Menu Principal</SectionTitle>
              <MenuItem>
                <MenuIcon><FaHome /></MenuIcon>
                <Link to="/" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Accueil</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaUsers /></MenuIcon>
                <Link to="/membres" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Liste des Membres</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaUser /></MenuIcon>
                <Link to={`/profile/${user?.username}`} onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Profil</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaEuroSign /></MenuIcon> {/* Mise à jour avec l'icône d'Euro */}
                <Link to="/recapmercirecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Recap Merci Reçu</MenuText>
                </Link>
              </MenuItem>
              <MenuItem>
                <MenuIcon><FaChartBar /></MenuIcon>
                <Link to="/statistiquesmembre" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                  <MenuText>Statistiques</MenuText>
                </Link>
              </MenuItem>

              <SectionTitle onClick={openActivitySubMenu} style={{ cursor: 'pointer' }}>
                Mon activité
              </SectionTitle>
            </>
          ) : (
            <>
              <SectionTitle>Mon activité</SectionTitle>
              <SubMenu>
                <MenuItem>
                  <MenuIcon><FaHandshake /></MenuIcon>
                  <Link to="/entrerunmerci" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer un Merci</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaSignInAlt /></MenuIcon>
                  <Link to="/entreruneconnexion" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer une Connexion</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaFileInvoice /></MenuIcon>
                  <Link to="/entreruneentrevue" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer une Entrevue</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaUserTie /></MenuIcon>
                  <Link to="/entreruninvite" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Entrer un Invité</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaEuroSign /></MenuIcon> {/* Mise à jour dans le menu Mon activité */}
                  <Link to="/recapmercirecu" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Merci Reçu</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaHandsHelping /></MenuIcon>
                  <Link to="/recapmercidonne" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Recap Mercis Donnés</MenuText>
                  </Link>
                </MenuItem>
                <MenuItem>
                  <MenuIcon><FaUserTie /></MenuIcon>
                  <Link to="/listedesinvites" onClick={() => dispatch({ type: 'CLOSE_MENU' })}>
                    <MenuText>Liste des Invités</MenuText>
                  </Link>
                </MenuItem>
              </SubMenu>

              <BackLink onClick={closeActivitySubMenu}>
                <FaArrowLeft />
                <span>Retour au menu principal</span>
              </BackLink>
            </>
          )}

          <MenuItem onClick={handleLogout}>
            <MenuIcon><FaSignOutAlt /></MenuIcon>
            <MenuText>Déconnexion</MenuText>
          </MenuItem>
        </MenuContainer>
      )}
    </>
  );
};

export default MenuComponent;
