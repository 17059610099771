import React from 'react';
import App from '../App'; // Chemin correct vers App.js
import { UserProvider } from './UserContext'; // Chemin correct vers UserContext.js
import { BrowserRouter } from 'react-router-dom';

const AppWrapper = () => (
  <BrowserRouter>
    <UserProvider>
      <App />
    </UserProvider>
  </BrowserRouter>
);

export default AppWrapper;

