import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import UserContext from './UserContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px;
    box-shadow: none;
  }
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  & > div {
    flex: 1;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #d9e1e8;
    padding: 10px;
    text-align: center;
    font-family: 'Avenir Next', sans-serif;
    color: #005357;
    background-color: #f2f6f6;
  }

  th {
    font-weight: 700;
    color: #ffffff;
    background-color: #005357;
  }

  @media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }

    th {
      display: none; /* Cache les en-têtes dans la version mobile */
    }

    td {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      border: 1px solid #d9e1e8;
      position: relative;
      padding-left: 50%;
      text-align: left; /* Aligne les textes à gauche */
    }

    td::before {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label); /* Utilise les labels pour les colonnes */
      font-weight: bold;
      color: #005357;
    }

    tr {
      margin-bottom: 15px;
      border: 1px solid #d9e1e8;
      border-radius: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;

  button {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    button {
      margin-left: 0;
      margin-bottom: 15px;
    }
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #00A2A2;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const FormControl = styled.div`
  margin-bottom: 20px;
  flex: 1;
`;

const SearchInput = styled(Input)`
  padding: 15px;
  font-size: 18px;
`;

function ListeDesInvites() {
  const { user } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/invites')
      .then(response => response.json())
      .then(data => setInvites(data))
      .catch(error => console.error('Erreur lors de la récupération des invités:', error));
  }, [user]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredInvites = useMemo(() => {
    return invites.filter(invite =>
      invite.nom.toLowerCase().includes(search.toLowerCase()) ||
      invite.prenom.toLowerCase().includes(search.toLowerCase()) ||
      invite.societe.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, invites]);

  const downloadPDF = () => {
    if (filteredInvites.length === 0) {
      alert('Aucun invité à exporter');
      return;
    }

    const doc = new jsPDF();
    doc.autoTable({
      head: [['Nom', 'Prénom', 'Société', 'Téléphone', 'Email', 'Date de Visite', 'Invitant']],
      body: filteredInvites.map(invite => [
        invite.nom, invite.prenom, invite.societe, invite.telephone, invite.mail, format(new Date(invite.date_visite), 'dd/MM/yyyy', { locale: fr }), `${user.nom} ${user.prenom}`
      ])
    });
    doc.save('liste_invites.pdf');
  };

  const downloadExcel = () => {
    if (filteredInvites.length === 0) {
      alert('Aucun invité à exporter');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(filteredInvites.map(invite => ({
      Nom: invite.nom,
      Prénom: invite.prenom,
      Société: invite.societe,
      Téléphone: invite.telephone,
      Email: invite.mail,
      'Date de Visite': format(new Date(invite.date_visite), 'dd/MM/yyyy', { locale: fr }),
      Invitant: `${user.nom} ${user.prenom}`
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invités');
    XLSX.writeFile(workbook, 'liste_invites.xlsx');
  };

  return (
    <Container>
      <Title>Liste des invités</Title>
      <Form>
        <Row>
          <FormControl>
            <SearchInput
              type="text"
              placeholder="Recherche"
              value={search}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Row>

        <TableContainer>
          <Table role="table">
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Société</th>
                <th>Téléphone</th>
                <th>Email</th>
                <th>Date de Visite</th>
                <th>Invitant</th>
              </tr>
            </thead>
            <tbody>
              {filteredInvites.map((invite) => (
                <tr key={invite.email}>
                  <td data-label="Nom">{invite.nom}</td>
                  <td data-label="Prénom">{invite.prenom}</td>
                  <td data-label="Société">{invite.societe}</td>
                  <td data-label="Téléphone">{invite.telephone}</td>
                  <td data-label="Email">{invite.mail}</td>
                  <td data-label="Date de Visite">{format(new Date(invite.date_visite), 'dd/MM/yyyy', { locale: fr })}</td>
                  <td data-label="Invitant">{`${user.nom} ${user.prenom}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>

        <ButtonContainer>
          <Button type="button" onClick={downloadPDF}>Télécharger Liste PDF</Button>
          <Button type="button" onClick={downloadExcel}>Télécharger Liste Excel</Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
}

export default ListeDesInvites;

