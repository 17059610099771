import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';
import UserContext from './UserContext';

registerLocale('fr', fr);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Suppression du padding sur smartphone */
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px; /* Augmentation de la taille du titre sur smartphone */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Suppression du padding sur smartphone */
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 18px; /* Augmentation de la taille de la police sur smartphone */
  }
`;

const SmallInput = styled.input`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 18px;
    font-size: 18px; /* Augmentation de la taille des boutons sur smartphone */
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const MerciList = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

const MerciItem = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const MerciDetail = styled.p`
  padding: 5px 0;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px; /* Augmentation de la taille de la police sur smartphone */
  }
`;

const BoldText = styled(MerciDetail)`
  font-weight: bold;
`;

const SectionTitle = styled.h2`
  margin: 20px 0 10px 0;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px; /* Augmentation de la taille sur smartphone */
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 20px;
`;

const InfoMessage = styled.p`
  color: #005357;
  margin-top: 20px;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px; /* Augmentation de la taille sur smartphone */
  }
`;

const UserProfileImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const GreyLine = styled.hr`
  border: none;
  border-top: 1px solid #d9e1e8;
  margin: 20px 0;
`;

const ItalicText = styled.p`
  font-style: italic;
  color: #005357;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const RecapMerciRecu = () => {
  const { user } = useContext(UserContext);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mercis, setMercis] = useState([]);
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('Nous affichons par défaut les 5 derniers mercis.');

  useEffect(() => {
    if (user) {
      setNom(user.nom);
      setPrenom(user.prenom);
      fetchMercis(user.id);
    }
  }, [user]);

  const fetchMercis = async (userId, startDate = null, endDate = null) => {
    let url = `https://app.konex-business.fr/api/thanks_received?user_id=${userId}`;
    if (startDate && endDate) {
      url += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    try {
      const response = await fetch(url);
      const data = await response.json();
      setMercis(data);
      if (startDate && endDate) {
        setInfoMessage(`Affichage des mercis reçus du ${startDate.toLocaleDateString('fr-FR')} au ${endDate.toLocaleDateString('fr-FR')}`);
      } else {
        setInfoMessage('Nous affichons par défaut les 5 derniers mercis.');
      }
      if (data.length === 0) {
        setInfoMessage('Aucun merci reçu pour cette période.');
      }
    } catch (error) {
      setError('Erreur lors de la récupération des mercis reçus');
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchMercis(user.id, startDate, endDate);
  };

  return (
    <Container>
      <Title>Merci Reçus</Title>
      <Form onSubmit={handleSearch}>
        <Row>
          <FormControl>
            <Label htmlFor="nom">Nom</Label>
            <SmallInput
              id="nom"
              type="text"
              value={nom}
              readOnly
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="prenom">Prénom</Label>
            <SmallInput
              id="prenom"
              type="text"
              value={prenom}
              readOnly
            />
          </FormControl>
        </Row>
        <FormControl>
          <Label htmlFor="periode">Période</Label>
          <StyledDatePicker
            id="periode"
            selected={startDate}
            onChange={(update) => {
              setStartDate(update[0]);
              setEndDate(update[1]);
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            locale="fr"
            dateFormat="dd/MM/yyyy"
            placeholderText="Sélectionner une période"
          />
        </FormControl>
        <Button type="submit">Rechercher</Button>
      </Form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {infoMessage && <InfoMessage>{infoMessage}</InfoMessage>}
      <MerciList>
        {mercis.map((merci) => (
          <MerciItem key={merci.id}>
            <ItalicText>Merci reçu le {new Date(merci.date).toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })}</ItalicText>
            <ProfileContainer>
              <UserProfileImage src={`https://konex-business.fr/${merci.donneur_profile_picture}`} alt="Profile" onError={(e) => { e.target.onerror = null; e.target.src = '/path_to_default_image'; }} />
              <div>
                <BoldText>{merci.donneur_prenom} {merci.donneur_nom}</BoldText>
                <MerciDetail>{merci.donneur_adresse}, {merci.donneur_cp}, {merci.donneur_ville}</MerciDetail>
                <MerciDetail>{merci.donneur_telephone}</MerciDetail>
              </div>
            </ProfileContainer>
            <GreyLine />
            <SectionTitle>INFORMATIONS MERCI</SectionTitle>
            <MerciDetail>Société : {merci.donneur_societe || 'Non spécifiée'}</MerciDetail>
            <MerciDetail>Email : {merci.donneur_email}</MerciDetail>
            <MerciDetail>Description : {merci.description}</MerciDetail>
            <MerciDetail>Montant : {merci.montant} €</MerciDetail>
          </MerciItem>
        ))}
      </MerciList>
    </Container>
  );
};

export default RecapMerciRecu;

