import React, { useContext } from 'react';
import UserContext from './UserContext'; // Chemin correct vers UserContext.js

const Diagnostic = () => {
  const { user } = useContext(UserContext);

  return (
    <div>
      <h1>Diagnostic</h1>
      <p>User: {user ? user.username : 'Not logged in'}</p>
    </div>
  );
};

export default Diagnostic;

