import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage';
import Accueil from './components/Accueil';
import EntrerUnMerci from './components/EntrerUnMerci';
import EntrerUneConnexion from './components/EntrerUneConnexion';
import EntrerUneEntrevue from './components/EntrerUneEntrevue';
import EntrerUnInvite from './components/EntrerUnInvite';
import RecapEntrevueRecu from './components/RecapEntrevueRecu';
import RecapEntrevueDonnee from './components/RecapEntrevueDonnee';
import RecapCaRecu from './components/RecapCaRecu';
import StatistiquesMembre from './components/StatistiquesMembre';
import ListeDesInvites from './components/ListeDesInvites';
import AdminStatGroup from './components/AdminStatGroup';
import Membres from './components/Membres';
import Profile from './components/Profile';
import SeConnecter from './components/SeConnecter';
import MenuComponent from './components/MenuComponent';
import Diagnostic from './components/Diagnostic';
import RecapKonexRecu from './components/RecapKonexRecu';
import RecapMerciDonne from './components/RecapMerciDonne';
import RecapMerciRecu from './components/RecapMerciRecu';
import BonsDonnes from './components/BonsDonnes';
import BonsRecus from './components/BonsRecus';
import Footer from './components/Footer'; // Import du Footer
import UserContext from './components/UserContext';
import axios from 'axios';

const App = () => {
    const { user, logout, login } = useContext(UserContext);
    const isLoggedIn = !!user;

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get('https://app.konex-business.fr/api/verifyToken', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.data.success) {
                        login(response.data.user);
                    } else {
                        logout();
                    }
                } catch (error) {
                    logout();
                }
            }
        };
        checkAuth();
    }, [login, logout]);

    return (
        <div className="app-container">
            <Routes>
                <Route path="/seconnecter" element={<SeConnecter />} />
                <Route path="/" element={isLoggedIn ? <Accueil /> : <Navigate to="/seconnecter" />} />
                <Route path="/diagnostic" element={<Diagnostic />} />
                <Route path="/entrerunmerci" element={isLoggedIn ? <EntrerUnMerci /> : <Navigate to="/seconnecter" />} />
                <Route path="/entreruneconnexion" element={isLoggedIn ? <EntrerUneConnexion /> : <Navigate to="/seconnecter" />} />
                <Route path="/entreruneentrevue" element={isLoggedIn ? <EntrerUneEntrevue /> : <Navigate to="/seconnecter" />} />
                <Route path="/entreruninvite" element={isLoggedIn ? <EntrerUnInvite /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapentrevuerecu" element={isLoggedIn ? <RecapEntrevueRecu /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapentrevuedonnee" element={isLoggedIn ? <RecapEntrevueDonnee /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapcarecu" element={isLoggedIn ? <RecapCaRecu /> : <Navigate to="/seconnecter" />} />
                <Route path="/statistiquesmembre" element={isLoggedIn ? <StatistiquesMembre /> : <Navigate to="/seconnecter" />} />
                <Route path="/listedesinvites" element={isLoggedIn ? <ListeDesInvites /> : <Navigate to="/seconnecter" />} />
                <Route path="/adminstatgroup" element={isLoggedIn ? <AdminStatGroup /> : <Navigate to="/seconnecter" />} />
                <Route path="/membres" element={isLoggedIn ? <Membres /> : <Navigate to="/seconnecter" />} />
                <Route path="/profile/:username" element={isLoggedIn ? <Profile /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapkonexrecu" element={isLoggedIn ? <RecapKonexRecu /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapmercidonne" element={isLoggedIn ? <RecapMerciDonne /> : <Navigate to="/seconnecter" />} />
                <Route path="/recapmercirecu" element={isLoggedIn ? <RecapMerciRecu /> : <Navigate to="/seconnecter" />} />
                <Route path="/bonsdonnes" element={isLoggedIn ? <BonsDonnes /> : <Navigate to="/seconnecter" />} />
                <Route path="/bonsrecus" element={isLoggedIn ? <BonsRecus /> : <Navigate to="/seconnecter" />} />
            </Routes>
            {isLoggedIn && <MenuComponent isLoggedIn={isLoggedIn} user={user} onLogout={logout} />}
            <Footer /> {/* Ajout du Footer ici */}
        </div>
    );
};

export default App;

