import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import UserContext from './UserContext';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Avenir Next', sans-serif;
    background-color: #f2f2f2;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  @media (max-width: 1024px) {
    html {
      font-size: 15px;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 13px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 5px;
  }
`;

const BoxShadow = styled.div`
  display: flex;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  max-width: 800px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
  background-color: #fff;
  width: 400px;

  @media (max-width: 1024px) {
    width: 350px;
    padding: 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }

  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Title = styled.h1`
  margin-bottom: 20px;
  font-size: 24px;
  color: #005357;

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #005357;
`;

const InputField = styled.input`
  width: 100%;
  padding: 15px;
  border: 1px solid #d9e1e8;
  border-radius: 5px;
  background-color: #f2f6f6;

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const ForgotPassword = styled.div`
  text-align: left;
  margin-bottom: 20px;
  color: #005357;

  a {
    text-decoration: none;
    color: #005357;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const SubmitButtonContainer = styled.div`
  width: 100%;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #005357;
  color: white;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 12px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

const Alert = styled.div`
  margin-bottom: 20px;
  color: red;
`;

const Message = styled.p`
  margin-bottom: 20px;
  color: #005357;
  font-weight: bold;
`;

const LogoutButton = styled.button`
  padding: 15px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
`;

const ImageSection = styled.div`
  background-image: url('https://konexb.pixfeed.net/img/connexionimg.png');
  background-size: cover;
  background-position: center;
  width: 600px;
  height: auto;

  @media (max-width: 1024px) {
    width: 500px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  height: 50px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileLogo = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 70px;
    position: relative;
    top: -40px; /* Remonter le logo à -40px indépendamment du formulaire */
    margin-bottom: 20px;
    align-self: center;
  }
`;

const SeConnecter = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { user, login, logout } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://app.konex-business.fr/api/login', {
        username,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Ajout d'un console.log pour vérifier la réponse API
      console.log('Réponse API:', response);

      if (response.data.success) {
        login(response.data.user); // Envoie les données utilisateur au UserContext
        navigate(`/profile/${username}`); // Redirige vers la page profil
      } else {
        setMessage('Identifiants incorrects.');
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message || 'Erreur lors de la connexion.');
      } else if (error.request) {
        setMessage('Aucune réponse reçue du serveur.');
      } else {
        setMessage('Erreur lors de la configuration de la requête.');
      }
    }
  };

  const handleLogout = () => {
    logout();
    setMessage('');
    setUsername('');
    setPassword('');
    navigate('/seconnecter');
  };

  useEffect(() => {
    if (user) {
      setMessage(`Vous êtes déjà connecté en tant que ${user.prenom} ${user.nom}`);
    } else {
      setMessage('');
    }
  }, [user]);

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <a href="https://app.konex-business.fr/">
            <Logo src="https://konex-business.fr/img/LOGO-KONEX-V.svg" alt="Logo" />
          </a>
        </Header>

        {/* Logo mobile remonté de -40px */}
        <MobileLogo src="https://app.konex-business.fr/favicon2.png" alt="Logo Mobile" />

        <BoxShadow>
          <FormSection>
            <FormContainer>
              <Title><span style={{ color: '#00A2A2' }}>KONEX</span><span>ion des membres</span></Title>
              {message && !user && <Alert>{message}</Alert>}
              {!user ? (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label htmlFor="username">Identifiant</Label>
                    <InputField
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Entrez votre identifiant"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="password">Mot de passe</Label>
                    <InputField
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Entrez votre mot de passe"
                      required
                    />
                  </FormGroup>
                  <ForgotPassword>
                    <a href="https://konex-business.fr/forgot_userspassword.php">Mot de passe oublié ?</a>
                  </ForgotPassword>
                  <FormGroup>
                    <SubmitButtonContainer>
                      <SubmitButton type="submit">Connexion</SubmitButton>
                    </SubmitButtonContainer>
                  </FormGroup>
                </form>
              ) : (
                <div>
                  <Message>Vous êtes déjà connecté en tant que {user.prenom} {user.nom}.</Message>
                  <LogoutButton onClick={handleLogout}>Déconnexion</LogoutButton>
                </div>
              )}
            </FormContainer>
          </FormSection>
          <ImageSection />
        </BoxShadow>
      </Container>
    </>
  );
};

export default SeConnecter;

