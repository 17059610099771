import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px;
    box-shadow: none;
  }
`;

const Label = styled.label`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 10px;
  box-sizing: border-box;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  height: 120px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
    height: 100px;
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px;
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  & > div {
    flex: 1;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

function RecapCaRecu() {
  const [de, setDe] = useState('');
  const [date, setDate] = useState('');
  const [a, setA] = useState('');
  const [montant, setMontant] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Gestion de la soumission du formulaire
  };

  return (
    <Container>
      <Title>Récapitulatif chiffre d'affaire reçu</Title>
      <Form onSubmit={handleSubmit}>
        <FormControl>
          <Label htmlFor="de">De :</Label>
          <Input
            id="de"
            type="text"
            value={de}
            onChange={(e) => setDe(e.target.value)}
            placeholder="De"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="date">Date :</Label>
          <Input
            id="date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Date"
          />
        </FormControl>
        <FormControl>
          <Label htmlFor="a">À :</Label>
          <Input
            id="a"
            type="text"
            value={a}
            onChange={(e) => setA(e.target.value)}
            placeholder="À"
          />
        </FormControl>
        <Row>
          <FormControl>
            <Label htmlFor="montant">Pour un montant de :</Label>
            <Input
              id="montant"
              type="text"
              value={montant}
              onChange={(e) => setMontant(e.target.value)}
              placeholder="Montant"
            />
          </FormControl>
          <Label style={{ marginTop: '30px' }}>€</Label>
        </Row>
        <FormControl>
          <Label htmlFor="description">Description :</Label>
          <TextArea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
          />
        </FormControl>
        <FormControl>
          <Button type="submit">Entrer</Button>
        </FormControl>
      </Form>
    </Container>
  );
}

export default RecapCaRecu;
