import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';
import UserContext from './UserContext';
import { FaHandshake, FaUsers, FaEuroSign, FaInfoCircle } from 'react-icons/fa';

registerLocale('fr', fr);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  min-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;
`;

const InfoMessage = styled.p`
  color: #005357;
  margin-top: 20px;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  text-align: center;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.label`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
`;

const BonsList = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BonItem = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BonType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: bold;
  font-size: 16px;
`;

const BonDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const BonDescriptionToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  font-style: italic;
`;

const BonDescription = styled.p`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 10px;
  background-color: #f2f6f6;
  border-radius: 5px;
  display: ${props => (props.show ? 'block' : 'none')};
`;

const BonsRecus = () => {
  const { user } = useContext(UserContext);
  const [bons, setBons] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 15)));
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState('');
  const [expandedBon, setExpandedBon] = useState(null);

  useEffect(() => {
    if (user) {
      fetchBonsRecus(user.id);
    }
  }, [user, startDate, endDate]);

  const fetchBonsRecus = async (userId) => {
    const url = `https://app.konex-business.fr/api/bons_recus?user_id=${userId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setBons(data);
    } catch (error) {
      setError('Erreur lors de la récupération des bons reçus');
    }
  };

  const toggleDescription = (index) => {
    setExpandedBon(expandedBon === index ? null : index);
  };

  return (
    <Container>
      <Title>Récapitulatif « Bons » reçus</Title>
      <InfoMessage>Affichage des bons reçus pour les 15 derniers jours. Utilisez les sélecteurs de date pour modifier la période.</InfoMessage>
      <DateContainer>
        <div>
          <Label htmlFor="startDate">Du :</Label>
          <StyledDatePicker
            id="startDate"
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>
          <Label htmlFor="endDate">Au :</Label>
          <StyledDatePicker
            id="endDate"
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </DateContainer>
      {error && <InfoMessage>{error}</InfoMessage>}
      <BonsList>
        {bons.map((bon, index) => (
          <BonItem key={index}>
            <BonHeader>
              <BonType>
                {bon.type === 'connexion' && <FaHandshake />}
                {bon.type === 'entretien' && <FaUsers />}
                {bon.type === 'merci' && <FaEuroSign />}
                {bon.type.charAt(0).toUpperCase() + bon.type.slice(1)} de {bon.nom} {bon.prenom}
              </BonType>
              <FaInfoCircle onClick={() => toggleDescription(index)} />
            </BonHeader>
            <BonDetails>
              <BonDescriptionToggle onClick={() => toggleDescription(index)}>
                Voir la description
              </BonDescriptionToggle>
              <BonDescription show={expandedBon === index}>
                {bon.description}
              </BonDescription>
            </BonDetails>
          </BonItem>
        ))}
      </BonsList>
    </Container>
  );
};

export default BonsRecus;

