import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialiser le state avec les données stockées dans localStorage, si disponibles
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [notifications, setNotifications] = useState([]); // Ajout du state pour les notifications

  // Fonction pour ajouter une notification
  const addNotification = (message) => {
    const notification = {
      id: new Date().getTime(), // Générer un ID unique basé sur le timestamp
      message: message,
      timestamp: new Date().toISOString(),
    };

    setNotifications((prev) => [...prev, notification]); // Ajouter la notification à la liste
  };

  // Fonction de login pour gérer l'utilisateur et les stocker dans le localStorage
  const login = (userData) => {
    if (userData && userData.id) {  // Vérification de l'existence d'un ID utilisateur
      console.log('Storing user data in context:', userData);
      setUser(userData);
      localStorage.setItem('user', JSON.stringify(userData)); // Stocker dans localStorage
    } else {
      console.error('Invalid userData object passed to login:', userData);
    }
  };

  // Fonction de logout pour supprimer l'utilisateur du state et du localStorage
  const logout = () => {
    console.log('Clearing user data from context');
    setUser(null);
    localStorage.removeItem('user'); // Supprimer du localStorage
  };

  // Utilisation de useEffect pour vérifier si un utilisateur est déjà stocké dans le localStorage
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Charger l'utilisateur stocké si existant
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, login, logout, notifications, addNotification }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

