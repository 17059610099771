import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Pas de padding sur smartphone */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0; /* Pas de padding sur smartphone */
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 18px; /* Augmentation de la taille de la police sur smartphone */
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;

  & > div {
    flex: 1;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const FormControl = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const SmallInput = styled.input`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const Input = styled.input`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const TextArea = styled.textarea`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  height: 120px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 150px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const Select = styled.select`
  padding: 15px;
  border: 1px solid #d9e1e8;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  color: #005357;
  background-color: #f2f6f6;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 18px;
    height: 55px;
    width: 100%; /* Largeur maximale sur smartphone */
  }
`;

const Button = styled.button`
  padding: 15px;
  background-color: #005357;
  color: #fff;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 18px;
    font-size: 18px;
  }
`;

const Title = styled.h1`
  color: #005357;
  font-family: 'Avenir Next', sans-serif;
  font-weight: 700;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Message = styled.p`
  margin-top: 20px;
  color: ${props => (props.error ? 'red' : 'green')};
  font-family: 'Avenir Next', sans-serif;
  font-weight: 500;
`;

function EntrerUneConnexion() {
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [societe, setSociete] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [adresse, setAdresse] = useState('');
  const [cp, setCp] = useState('');
  const [ville, setVille] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch('https://app.konex-business.fr/api/users')
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Erreur lors de la récupération des utilisateurs');
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    const connexionData = {
      userId,
      nom,
      prenom,
      societe,
      telephone,
      email,
      adresse,
      cp,
      ville,
      description,
    };

    // Envoyer la nouvelle connexion
    try {
      const response = await fetch('https://app.konex-business.fr/api/connexion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(connexionData),
      });

      if (!response.ok) {
        setError('Erreur lors de l\'enregistrement de la connexion.');
        return;
      }

      const data = await response.json();

      if (data.success) {
        setMessage('Connexion enregistrée avec succès');
      } else {
        setError('Erreur lors de l\'enregistrement de la connexion.');
      }
    } catch (error) {
      setError('Erreur lors de l\'enregistrement de la connexion.');
    }
  };

  return (
    <Container>
      <Title>Entrer une Connexion</Title>
      {loading ? (
        <p>Chargement...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          <FormControl>
            <Label htmlFor="userId">À</Label>
            <Select id="userId" value={userId} onChange={(e) => setUserId(e.target.value)}>
              <option value="">Choisir un utilisateur</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.nom} {user.prenom}
                </option>
              ))}
            </Select>
          </FormControl>
          <Row>
            <FormControl>
              <Label htmlFor="nom">Nom</Label>
              <SmallInput
                id="nom"
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                placeholder="Nom"
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="prenom">Prénom</Label>
              <SmallInput
                id="prenom"
                type="text"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                placeholder="Prénom"
              />
            </FormControl>
          </Row>
          <FormControl>
            <Label htmlFor="societe">Société</Label>
            <Input
              id="societe"
              type="text"
              value={societe}
              onChange={(e) => setSociete(e.target.value)}
              placeholder="Société"
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="telephone">Téléphone</Label>
            <Input
              id="telephone"
              type="text"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              placeholder="Téléphone"
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="adresse">Adresse</Label>
            <Input
              id="adresse"
              type="text"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              placeholder="Adresse"
            />
          </FormControl>
          <Row>
            <FormControl>
              <Label htmlFor="cp">CP</Label>
              <SmallInput
                id="cp"
                type="text"
                value={cp}
                onChange={(e) => setCp(e.target.value)}
                placeholder="CP"
              />
            </FormControl>
            <FormControl>
              <Label htmlFor="ville">Ville</Label>
              <SmallInput
                id="ville"
                type="text"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
                placeholder="Ville"
              />
            </FormControl>
          </Row>
          <FormControl>
            <Label htmlFor="description">Description</Label>
            <TextArea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
          </FormControl>
          <FormControl>
            <Button type="submit">Entrer</Button>
          </FormControl>
          {message && <Message>{message}</Message>}
          {error && <Message error>{error}</Message>}
        </Form>
      )}
    </Container>
  );
}

export default EntrerUneConnexion;

